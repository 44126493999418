import clsx from 'clsx';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import React, { Suspense } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';
import { getMapUrl } from '@/lib/getMapUrl.ts';
import { getPhone } from '@/lib/getPhone.ts';
import Header from '../Header/Header.tsx';
import { ReservationAtom } from '../lib/state.ts';
import styles from './Confirmation.module.css';
import NeedToKnows from '@/components/NeedToKnows/NeedToKnows.tsx';
import DownloadDorsia from '@/components/DownloadDorsia/DownloadDorsia.tsx';

const Confirmation: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 800px)' });
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });
  const navigate = useNavigate();
  const reservation = useAtomValue(ReservationAtom);
  const width = isMobile ? 800 : 258;
  const height = isMobile ? 427 : 263;
  const map_url = getMapUrl(
    width,
    height,
    reservation?.restaurant ?? undefined,
    isRetina
  );

  const onRestart = () => {
    navigate('../booking');
  };

  if (!reservation) {
    return (
      <>
        <Header title="Loading confirmation" step={0} />
        <div className="content"></div>
      </>
    );
  }

  const flat_address = reservation?.restaurant.address
    ? reservation.restaurant.address.replace(/[\r\n]+/, '')
    : '';
  const user_phone = getPhone(reservation.user.phone);
  const rest_phone = getPhone(reservation.restaurant.phone);
  const tzd = dayjs(reservation.reservation_at).tz(
    reservation.restaurant.timezone
  );

  return (
    <>
      <Header title="Booking confirmed" step={0} />

      <div className="content">
        <div className={styles.grid}>
          <div>
            <h2>{reservation.restaurant.name}</h2>

            <div className="section">
              <div>{tzd.format('ddd, MMMM D')}</div>
              <div>{tzd.format('h:mm a')}</div>
              <div>{reservation.party_size} guests</div>
              <div>{reservation.table_type}</div>

              {reservation.table_minimum > 0 && (
                <div className={styles.minimum}>
                  {reservation.table_minimum__currency} total minimum
                </div>
              )}
              {reservation.price_per_person > 0 && (
                <div className={styles.minimum_pp}>
                  {reservation.price_per_person__currency} minimum per person
                </div>
              )}
            </div>

            <div className="section">
              <div>
                {reservation.user.first_name} {reservation.user.last_name}
              </div>
              <div>{!!user_phone && user_phone.formatNational()}</div>
              <div>{reservation.user.email}</div>
            </div>
          </div>

          <div className={styles.mapContainer}>
            {map_url && reservation?.restaurant.address && (
              <img className={styles.map} src={map_url.toString()} alt="Map" />
            )}
          </div>
        </div>

        {!isMobile && <hr />}

        <div className={clsx('section', styles.info)}>
          <div className={styles.content}>
            <div>{reservation.restaurant.name}</div>
            <Address address={reservation.restaurant.address} />
            {!!rest_phone && (
              <div className={styles.phone}>
                <a href={rest_phone.getURI()} className={styles.link}>
                  {rest_phone.formatNational()}
                </a>
              </div>
            )}
            {reservation.restaurant.website && (
              <a href={reservation.restaurant.website} className={styles.link}>
                {reservation.restaurant.website}
              </a>
            )}
          </div>
          {reservation?.restaurant.address && (
            <a
              className={styles.directions}
              href={`https://maps.google.com/?q=${flat_address}`}
              target="_blank"
            >
              Directions
            </a>
          )}
        </div>

        <hr />

        <div className={clsx('section', styles.needToKnow)}>
          <h3>Need to know</h3>

          <Suspense fallback={<Loading />}>
            <NeedToKnows need_to_knows={reservation.restaurant.need_to_knows} />
          </Suspense>
        </div>

        <hr />

        <DownloadDorsia
          reservation={reservation.restaurant.id}
        />

        <hr />

        <div className="section">
          <button className="nextButton" onClick={onRestart}>
            Create a New Reservation
          </button>
        </div>
      </div>
    </>
  );
};

interface AddressProps {
  address: string;
}

const Address: React.FC<AddressProps> = ({ address }) => {
  const split = address.replace('\r', '').split('\n');

  return (
    <>
      {split.map((line: string, i: number) => (
        <div key={i}>{line}</div>
      ))}
    </>
  );
};

const Loading: React.FC = () => {
  return <div>Wait...</div>;
};

export default Confirmation;
