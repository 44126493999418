import clsx from 'clsx';
import React, { useState } from 'react';
import { Scrollable } from '../../../components/Scrollable/Scrollable.tsx';
import { useWidgetState } from '../lib/state.ts';
import { AvailabilityOption } from '../../../lib/types.ts';

import styles from './ScrollableItem.module.css';

import User_light from '../../../assets/images/User_light.svg?react';
import User_dark from '../../../assets/images/User_dark.svg?react';

interface Props {
  option: AvailabilityOption;
}

const ReservationTables: React.FC = () => {
  const [widget] = useWidgetState();

  if (!widget.availability) {
    return <></>;
  }

  return (
    <Scrollable>
      {widget.availability.options.map((option: AvailabilityOption) => (
        <ReservationTable key={option.diff_key} option={option} />
      ))}
    </Scrollable>
  );
};

const ReservationTable: React.FC<Props> = ({ option }) => {
  const [widget, updateWidget] = useWidgetState();
  const [hover, setHover] = useState<boolean>(false);

  const current_chosen = widget.option?.diff_key === option.diff_key;
  const active = current_chosen || hover;

  const onEnter = () => setHover(true);
  const onLeave = () => setHover(false);
  const onClick = () =>
    updateWidget({
      option,
      reservation_error: undefined,
    });

  const className = clsx(styles.scrollableItem, {
    [styles.selected]: current_chosen,
    [styles.hover]: hover,
  });

  return (
    <div
      className={className}
      onClick={onClick}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
    >
      <div className={styles.title}>
        {active ? <User_light /> : <User_dark />}
        <span>{option.seats}</span>
      </div>
      {option.price > 0 && (
        <div className={styles.text}>{option.price__currency}/person</div>
      )}

      {!!option.type && <div className={styles.text}>{option.type}</div>}
    </div>
  );
};

export default ReservationTables;
